import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './modules/auth'
import patient from './modules/patient'
import appointment from './modules/appointment'
import address from './modules/address'
import clinic_patient from './modules/clinic_patient'
import treatment from './modules/treatment'
import calendar_new from './modules/calendar_new'
import schemes from './modules/schemes'
import share from './modules/share'
import pharmacy from './modules/pharmacy'
import patient_questionnaire from './modules/patient_questionnaire'
import inventory from './modules/inventory'
import inter_consultations from './modules/inter_consultations'
import insurance_files from './modules/insurance_files'
import studies from './modules/studies'
import calendarStoreModule from '../components/Appointments/calendarStoreModule'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
    isCompression: false,
});

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],

    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        patient,
        appointment,
        calendarStoreModule,
        address,
        clinic_patient,
        treatment,
        calendar_new,
        schemes,
        share, patient_questionnaire,
        pharmacy,
        inventory,
        inter_consultations,
        insurance_files,
        studies
    },
    strict: process.env.DEV,
})
