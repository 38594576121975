import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findAllInventory({commit},data) {
            const response = await requestFetch(`/api/v2/inventory/all?${data}`, 'GET');
            return response;
        },
        async findListPartners({commit}) {
            const response = await requestFetch(`/api/v2/inventory/listPartners`, 'GET');
            return response;
        },
        async createInventory({commit}, data) {
            const response = await requestFetch(`/api/v2/inventory/newInventory`, 'POST', data);
            return response;
        },
        async findOneInventory({commit}, id) {
            const response = await requestFetch(`/api/v2/inventory/one/${id}`, 'GET');
            return response;
        },
        async findOneInventoryOut({commit}, id) {
            const response = await requestFetch(`/api/v2/inventory/oneOut/${id}`, 'GET');
            return response;
        },
        async updateAll({commit}, {id,data}) {
            const response = await requestFetch(`/api/v2/inventory/update/${id}`, 'PUT', data);
            return response;
        },
        async createMoveLine({commit},data) {
            const response = await requestFetch(`/api/v2/inventory/stock_move`, 'POST', data);
            return response;
        }, 
         async createMoveLines({commit},data) {
            const response = await requestFetch(`/api/v2/inventory/stock_move_line`, 'POST', data);
            return response;
        },
        async verifyMoveLine({commit},{move_id, picking_id}) {
            const response = await requestFetch(`/api/v2/inventory/verify_stock_move_line/${move_id}/${picking_id}`, 'GET');
            return response;
        },
        async verifyMoveLineOut({commit},{move_id, picking_id}) {
            const response = await requestFetch(`/api/v2/inventory/verify_stock_move_line_out/${move_id}/${picking_id}`, 'GET');
            return response;
        },
        async createStockLabel({commit},data) {
            const response = await requestFetch(`/api/v2/inventory/create_medication/`, 'POST', data);
            return response;
        },
        async validateStock({commit},id) {
            const response = await requestFetch(`/api/v2/inventory/validate_stock_picking/${id}`, 'GET');
            return response;
        },
        async validateStockOut({commit},id) {
            const response = await requestFetch(`/api/v2/inventory/validate_stock_picking_out/${id}`, 'GET');
            return response;
        },
        async validateReady({commit},id) {
            const response = await requestFetch(`/api/v2/inventory/validate_stock_ready/${id}`, 'GET');
            return response;
        },
        async deleteLine({commit},id) {
            const response = await requestFetch(`/api/v2/inventory/delete_line/${id}`, 'DELETE');
            return response;
        },
        async deleteMoveLine({commit},id) {
            const response = await requestFetch(`/api/v2/inventory/delete_line_move_line/${id}`, 'DELETE');
            return response;
        },
        async updateMoveLine({commit}, {id, data}) {
            const response = await requestFetch(`/api/v2/inventory/update_move_line/${id}`, 'PUT', data);
            return response;
        },
        async updateMoveLineQty({commit}, data) {
            const response = await requestFetch(`/api/v2/inventory/update_qty_line`, 'POST', data);
            return response;
        },
        async verifyExpirationDate({commit}, {id, data}) {
            const response = await requestFetch(`/api/v2/inventory/verify_expiration_date/${id}`, 'PUT', data);
            return response;
        },
        async verifyStockAllSave({commit},data) {
            const response = await requestFetch(`/api/v2/inventory/verify_lot_all/`, 'POST', data);
            return response;
        },
    },
    getters: {
    }

}
