import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findStudiesLab({commit}, data) {
            const response = await requestFetch(`/api/v2/studiesLab?name=${data}`, 'GET');
            return response;
        },

        async findStudiesAll({commit}, data) {
            const response = await requestFetch(`/api/v2/studiesLab/consultations/${data}`, 'GET');
            return response;
        },
        async createProductStudiesLab({commit}, data) {
            const response = await requestFetch(`/api/v2/studiesLab`, 'POST', data);
            if (response.msg){
                return response.msg
            }else{
                return {
                    error:"No se pudo crear el producto"
                }
            }
        },

        async findDiagnostics({commit}, data) {
            const response = await requestFetch(`/api/eleonor/consultations/listDiagnostics?name=${data}`, 'GET');
            return response;
        },
        async createProductDiagnostics({commit}, data) {
            const response = await requestFetch(`/api/eleonor/consultations/diagnostics`, 'POST', data);
            if (response.msg){
                return response.msg
            }else{
                return {
                    error:"No se pudo crear el producto"
                }
            }
        },

        async findLaboratoriesAll({commit}, data) {
            const response = await requestFetch(`/api/v2/studiesLab/laboratories`, 'GET');
            return response;
        },

    },
    getters: {
    }

}
