import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findPatients({commit}) {
            const id = await store.getters["auth/getUser"].id;
            const response = await requestFetch(`/api/patient/all/${id}`, 'GET');
            return response.patients;

        },

        async updatePhotoPatient({commit},{id, data}) {
            const response = await requestFetch(`/api/patient/updatePhotoPatient/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async findPatientsSearchAll({commit},params) {
            const response = await requestFetch(`/api/patient/all_search_params?${params.toString()}`, 'GET');
            return response.patients;

        },
        async findPatientOne({commit}, id) {
            const response = await requestFetch(`/api/patient/one/${id}`, 'GET');
            return response.patient;

        },
        async searchPrimaryFile({commit}, id) {
            const response = await requestFetch(`/api/eleonor/files/patientTreatmentPrimary/${id}`, 'GET');
            return response;

        },
        async findPatientOneInformation({commit}, id) {
            const response = await requestFetch(`/api/eleonor/information/patient/${id}`, 'GET');
            return response.information;

        },
        async findPatientOneHistory({commit}, id) {
            const response = await requestFetch(`/api/eleonor/medical_history/patient/${id}`, 'GET');
            return response.history;

        },
        async findPatientAllFiles({commit}, {id,type}) {
            const response = await requestFetch(`/api/eleonor/files/patient/${id}/${type}`, 'GET');
            return response.files;

        },
        async findPatientOneFiles({commit}, {id,patient}) {
            const response = await requestFetch(`/api/eleonor/files/patient_one/${id}/${patient}`, 'GET');
            return response.files;

        },
        async findPatientAllConsultations({commit}, id) {
            const response = await requestFetch(`/api/eleonor/consultations/all/${id}`, 'GET');
            return response.consultations;

        },

        async findListAnswerTreatments({commit}) {
            const response = await requestFetch(`/api/eleonor/consultations/listAnswerTreatments`, 'GET');
            return response.answers;

        },
        async findListLineTreatments({commit}) {
            const response = await requestFetch(`/api/eleonor/consultations/listLineTreatments`, 'GET');
            return response.ciclesLine;

        },
        async findListTreatmentsLines({commit}) {
            const response = await requestFetch(`/api/eleonor/consultations/listTreatmentsLines`, 'GET');
            return response.treatmentsLines;

        },
        async findPatientAllConsultationsDesc({commit}, id) {
            const response = await requestFetch(`/api/eleonor/consultations/desc/${id}`, 'GET');
            return response.consultation;

        },
        async deleteFileRequest({commit}, id) {
            const response = await requestFetch(`/api/eleonor/consultations/${id}`, 'DELETE');
            return response;

        },
        async findPatientOneConsultations({commit}, id) {
            const response = await requestFetch(`/api/eleonor/consultations/one/${id}`, 'GET');
            return response.consultations;

        },
        async findPatientRecipe({commit}, id) {
            const response = await requestFetch(`/api/eleonor/recipe/one/${id}`, 'GET');
            return response.consultations;

        },
        async findPatientAllRecipe({commit}, id) {
            const response = await requestFetch(`/api/eleonor/recipe/all/${id}`, 'GET');
            return response.consultations;

        },
        async updateFileHistory({commit}, {id,data}) {
            const response = await requestFetch(`/api/eleonor/files/patient/${id}`, 'PUT',data);
            if (response){
                return true
            }else{
                return false
            }
        },
        async downloadFiPatientOnePreview({commit}, data) {
            const response = await requestFetch(`/api/eleonor/files/patient_preview/${data.id}`, 'GET');
            return response;
        },
        async changePrimaryRequest({commit}, {id,patient_id}) {
            const response = await requestFetch(`/api/eleonor/files/patient_primary/${id}/${patient_id}`, 'GET');
            return response;
        },
        async downloadFiPatientOne({commit},data) {
            const response = await requestFetch(`/api/eleonor/files/patient_download/${data.id}`, 'GET');
            var a = document.createElement('a');
            a.href = response.msg;
            a.download = response.msg
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
        async downloadFiConsultations({commit},id) {
            const response = await requestFetch(`/api/eleonor/consultations/downloadFile/${id}`, 'GET');
            var a = document.createElement('a');
            a.href = response.msg;
            a.download = response.msg
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
        async createPatientFiles({commit}, data) {
            const response = await requestFetch(`/api/eleonor/files/patient/`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async createConsultation({commit}, data) {
            const response = await requestFetch(`/api/eleonor/consultations`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async createRecipe({commit}, data) {
            const response = await requestFetch(`/api/eleonor/recipe`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async updateRecipeStore({commit}, data) {
            const response = await requestFetch(`/api/eleonor/recipe`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },

        async findMedicinesSearch({commit}, data) {
            const response = await requestFetch(`/api/eleonor/recipe/medicines?name=${data}`, 'GET');
            return response;
        },

        async createProductMedicines({commit}, data) {
            const response = await requestFetch(`/api/eleonor/recipe/medicines`, 'POST', data);
            if (response.msg){
                return response.msg
            }else{
                return {
                    error:"No se pudo crear el producto"
                }
            }
        },

        async updateConsultationStore({commit}, data) {
            const response = await requestFetch(`/api/eleonor/consultations`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async createHistoryMedical({commit}, data) {
            const response = await requestFetch(`/api/eleonor/medical_history/patient`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async createInformationGeneral({commit}, data) {
            const response = await requestFetch(`/api/eleonor/information/patient`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async updateInformationGeneral({commit}, {id, data}) {
            const response = await requestFetch(`/api/eleonor/information/patient/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async updateHistoricPatient({commit}, {id, data}) {
            const response = await requestFetch(`/api/eleonor/medical_history/patient/${id}`, 'PUT', data);
            console.log(response)
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async createPatient({commit}, data) {
            const response = await requestFetch(`/api/patient`, 'POST',data);
            if (response.msg){
                return {msg:true}
            }else{
                return {error:response.error}
            }

        },
        async updatePatient({commit}, data) {
            const response = await requestFetch(`/api/patient/update_patient`, 'PUT',data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async findPatientsSearch({commit},search) {
            const response = await requestFetch(`/api/patient/search/${search}`, 'GET');
            return response.patients;

        },
        async findPatientsSearchType({commit}, {search,type}) {
            const response = await requestFetch(`/api/patient/search_type/${search}/${type}`, 'GET');
            return response.patients;

        },
        async findPatientsSearchSelect({commit},search) {
            const response = await requestFetch(`/api/patient/search_select/${search}`, 'GET');
            return response.patients;

        },
        async findPatientsSearchSelectList({commit},search) {
            const response = await requestFetch(`/api/patient/search_select_list/${search}`, 'GET');
            return response.patients;

        },
        async findPricelistId({commit},data) {
            const response = await requestFetch(`/api/patient/price_list/${data}`, 'GET');
            return response.pricelist;

        },
        async findPricelistPatient({commit},data) {
            const response = await requestFetch(`/api/patient/price_list_object/${data}`, 'GET');
            return response.pricelist;

        },
        async findConsecutiveId({commit}) {
            const response = await requestFetch(`/api/patient/consecutive`, 'GET');
            return response.number;
        },

        async findAllTypeInsurance({commit}) {
            const response = await requestFetch(`/api/v2/insurers/types`, 'GET');
            return response.types;
        },
        async createInsuranceFile({commit},data) {
            const response = await requestFetch(`/api/v2/insurers/upload_insurers`, 'POST',data);
            console.log(response)
            if(response.msg){
                return true
            }else{
                return false
            }
          
        },

        async generateQrRecipe({commit},data) {
            const response = await requestFetch(`/api/app/qr/generateRecipe`, 'POST',data);
            return response;

        },

        async generateQrStudies({commit},data) {
            const response = await requestFetch(`/api/app/qr/generateQrStudies`, 'POST',data);
            return response;

        },
        async updateInsuranceFile({commit},data) {
            const response = await requestFetch(`/api/v2/insurers/update/${data.id}`, 'PUT',data);
            console.log(response)
            if(response.msg){
                return true
            }else{
                return false
            }
          
        },
        async findAllInsurers({commit},id) {
  
            const response = await requestFetch(`/api/v2/insurers/all_insurers/${id}`, 'GET');

            return response.files;
        },

        async getFindFullName({commit},id) {

            const response = await requestFetch(`/api/patient/get_doctor/${id}`, 'GET');

            return response;
        },
        async updateUserDoctor({commit},{id, data}) {

            const response = await requestFetch(`/api/patient/update_info_doctor/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async updateFileDoctor({commit},{id, data}) {

            const response = await requestFetch(`/api/patient/updateInfoSignature/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async findOneInsurers({commit},id) {
  
            const response = await requestFetch(`/api/v2/insurers/one_insurers/${id}`, 'GET');
            return response.files;
        },
        async deleteOneInsurers({commit},id) {
  
            const response = await requestFetch(`/api/v2/insurers/delete/${id}`, 'DELETE');
            if(response){
                return true
            }else{
                return false
            }

        },
        async downloadS3Aws({commit},name) {
            const send = {
                name
            }
  
            const response = await requestFetch(`/api/v2/insurers/download`, 'POST',send);
            return response.pdf;
        },
    },
    getters: {
    }

}
